import React from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'; // Correct import paths for v2

const UnderConstruction = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 via-purple-500 to-indigo-500 p-6">
      <div className="bg-white shadow-lg rounded-xl p-8 max-w-md text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Ahlersimmo</h1>
        <p className="text-lg text-gray-600 mb-8">
          Diese Seite ist im Moment im Neuaufbau. Sie können uns gerne über unsere Email Adresse
          oder per Telefonat kontaktieren.
        </p>
        <div className="flex flex-col items-center space-y-4">
          <div className="flex items-center space-x-2">
            <EnvelopeIcon className="h-6 w-6 text-blue-500" />
            <a href="mailto:info@ahlersimmo.de" className="text-lg text-blue-500 hover:underline">
              info@ahlersimmo.de
            </a>
          </div>
          <div className="flex items-center space-x-2">
            <PhoneIcon className="h-6 w-6 text-green-500" />
            <a href="tel:+4954619962196" className="text-lg text-green-500 hover:underline">
              05461 9962196
            </a>
          </div>
        </div>
        <div className="mt-8 space-x-4">
          <a
            href="mailto:info@ahlersimmo.de"
            className="inline-block bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
          >
            Email Us
          </a>
          <a
            href="tel:+4954619962196"
            className="inline-block bg-green-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-green-700 transition duration-300"
          >
            Call Us
          </a>
        </div>
        <p className="text-gray-400 mt-6 text-sm">
          Wir danken Ihnen für Ihr Verständnis.
        </p>
      </div>
    </div>
  );
};

export default UnderConstruction;