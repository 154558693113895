import React from 'react';
import UnderConstruction from './UnderConstruction'; // Import the component

function App() {
  return (
    <div className="App">
      <UnderConstruction /> {/* Render the UnderConstruction component */}
    </div>
  );
}

export default App;
